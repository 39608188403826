import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class FinancialReportsService {
    async get(fromDate,toDate) {
        const config = {
            headers: authHeader()
        };
        console.log('filter Data',fromDate +' -- '+toDate);
        const response = await axios
            .post(`${API_URL}financial-graph/highest-paying-client`, {fromDate, toDate}, config);

        return response.data;
    }
}

export default new FinancialReportsService();