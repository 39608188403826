<template>
  <!-- side popup modal -->
  <!--form
    method="post"
    @submit.prevent="submitHandler()"
    enctype="multipart/form-data"
  -->
    <div class="form-field-group">
      <!--h3 class="form-title">Import Finanical Report</h3>
      <h4 class="form-title pull-left">Step 1 <span v-if="getSpecificStep('step1') == 1" style="color:green;">: Report Completed</span></h4>
      <div class="row grid-1" v-if="getSpecificStep('step1') == 0">
        <div class="col form-group d-flex upload-wrap">
          <label class="form-label" for="type">Reports csv</label>
          <div>
            <div class="upload-file-field">
              <input
                class="form-control"
                type="file"
                ref="documents"
                @change="uploadDocuments('report')"
                :accept="`.${allowFileTypes.join(', .')}`"
              />
              <label class="placeholder">Reports csv</label>
              <span class="field-icon"
                ><font-awesome-icon :icon="['fa', 'cloud-upload-alt']" />
                UPLOAD</span
              >
            </div>
            <span
              v-if="submitted && v$.import_file.required.$invalid"
              class="error"
              >* required.</span
            >
            <div class="uploaded-doc">
              <template v-for="(document, index) in import_file" :key="index">
                <div class="uploaded-docoments">
                  <a :href="`${document.path}`" target="_blank">{{
                    document.name
                  }}</a>
                  <a
                    href="javascript:void(0);"
                    @click.prevent="removeDocument(index)"
                    ><span class="lnr lnr-cross-circle text-danger"></span
                  ></a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="form-btns" v-if="getSpecificStep('step1') == 0">
        <div class="d-flex justify-content-end">
          <button
            class="secondary-btn"
            title="Cancel"
            type="button"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="primary-btn"
            title="Update"
            type="submit"
            :disabled="submitted"
            @click.prevent="submitHandlerReports()"
          >
            Import
          </button>
        </div>
      </div-->
      <br/>
      <br/>
      
      <h4 class="form-title pull-left">Import Transactions <span v-if="getSpecificStep('step2') == 1" style="color:green;">: Transaction Completed</span></h4>
      <!--div class="row grid-1" v-if="getSpecificStep('step2') == 0"-->
        <div class="row grid-1">
        <div class="col form-group d-flex upload-wrap">
          <label class="form-label" for="type">Transaction csv</label>
          <div>
            <div class="upload-file-field">
              <input
                class="form-control"
                type="file"
                ref="documentstransaction"
                @change="uploadDocuments('transaction')"
                :accept="`.${allowFileTypes.join(', .')}`"
              />
              <label class="placeholder">Transaction csv</label>
              <span class="field-icon"
                ><font-awesome-icon :icon="['fa', 'cloud-upload-alt']" />
                UPLOAD</span
              >
            </div>
            <span
              v-if="submitted_trans && v$.import_trans_file.required.$invalid"
              class="error"
              >* required.</span
            >
            <div class="uploaded-doc">
              <template v-for="(document, index) in import_trans_file" :key="index">
                <div class="uploaded-docoments">
                  <a :href="`${document.path}`" target="_blank">{{
                    document.name
                  }}</a>
                  <a
                    href="javascript:void(0);"
                    @click.prevent="removeTransactionDocument(index)"
                    ><span class="lnr lnr-cross-circle text-danger"></span
                  ></a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="form-btns" v-if="getSpecificStep('step2') == 0">
        <div class="d-flex justify-content-end">
          <button
            class="secondary-btn"
            title="Cancel"
            type="button"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="primary-btn"
            title="Update"
            type="submit"
            :disabled="submitted_trans"
            @click.prevent="submitHandlerTransaction()"
          >
            Import
          </button>
        </div>
      </div>
      <!--br/>
      <br/>
      <h4 class="form-title pull-left">Step 2</h4>
      <div class="row grid-1">
        <div class="col form-group d-flex upload-wrap">
          <label class="form-label" for="type">Contract csv</label>
          <div>
            <div class="upload-file-field">
              <input
                class="form-control"
                type="file"
                ref="documentscontract"
                @change="uploadDocuments('contract')"
                :accept="`.${allowFileTypes.join(', .')}`"
              />
              <label class="placeholder">Contract csv</label>
              <span class="field-icon"
                ><font-awesome-icon :icon="['fa', 'cloud-upload-alt']" />
                UPLOAD</span
              >
            </div>
            <span
              v-if="submitted_contract && v$.import_contract_file.required.$invalid"
              class="error"
              >* required.</span
            >
            <div class="uploaded-doc">
              <template v-for="(document, index) in import_contract_file" :key="index">
                <div class="uploaded-docoments">
                  <a :href="`${document.path}`" target="_blank">{{
                    document.name
                  }}</a>
                  <a
                    href="javascript:void(0);"
                    @click.prevent="removeContractDocument(index)"
                    ><span class="lnr lnr-cross-circle text-danger"></span
                  ></a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="form-btns">
        <div class="d-flex justify-content-end">
          <button
            class="secondary-btn"
            title="Cancel"
            type="button"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="primary-btn"
            title="Update"
            type="submit"
            :disabled="submitted_contract"
            @click.prevent="submitHandlerContract()"
          >
            Import
          </button>
        </div>
      </div-->
    </div>
  <!--/form-->
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faCloudUploadAlt);
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import common from "../mixins/common";
import financialGraph from "../services/financialGraph.service";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      uploadPath: process.env.VUE_APP_UPLOAD_BASE_PATH,
      allowFileTypes: ["csv"],
      import_file: null,
      import_contract_file:null,
      import_trans_file:null,
      submitted: false,
      submitted_trans: false,
      submitted_contract: false,
    };
  },
  components: { FontAwesomeIcon },
  mixins: [common],
  validations() {
    return {
      import_file: { required },
      import_trans_file: { required },
      import_contract_file: { required },
    };
  },
  created(){
    let vm = this;
    vm.setSteps();
  },
  methods: {
    async submitHandlerReports() {
      let vm = this;
      vm.$store.dispatch("getUserDetails")
     
      vm.v$.import_file.$touch();
      vm.submitted = true;
      const isValidate = await vm.v$.import_file.$validate();
      //console.log('formData',isValidate);
      if (!isValidate) return;

      let formData = new FormData();
      //console.log('formData',vm.import_file[0]);
      formData.append("import_file", vm.import_file[0]);
      
      try {
        
        vm.$store.commit("loadingStatus", true);
          await vm.$store.dispatch("getUserDetails").then(async(response) => {
            if(response.is_active){
              //console.log('test',response.permissionData.financial_graphs);
              if (
                response.permissionData &&
                response.permissionData.financial_graphs &&
                response.permissionData.financial_graphs.length > 0 &&
                (response.permissionData.financial_graphs.includes("Add") || response.permissionData.financial_graphs.includes("Update"))
              ){
                      const response = await financialGraph.import_reports(formData);
                      vm.toastMessage(response.message, "success");
                      //vm.$emit("close");
                      vm.import_file = null;
                      vm.submitted = false;
                      vm.markStepComplete('step1'); 
                      //vm.$refs.documentstransaction.value = '';
                      vm.$refs.documentscontract.value = '';
                      vm.$emit("imported");
                }
              }
          });
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    async submitHandlerTransaction() {
     // console.log('transaction');
      let vm = this;
      vm.$store.dispatch("getUserDetails")
     /* if(vm.getSpecificStep('step1') !== 1)
      {
        vm.toastMessage('Please Complete Step 1', "error");
        vm.submitted_trans = false;
        return;
      }*/
      vm.submitted_trans = true;
      vm.v$.import_trans_file.$touch();
      
      const isValidate = await vm.v$.import_trans_file.$validate();
      if (!isValidate) return;

     
      let formData = new FormData();
      //console.log('formData',formData);
      formData.append("import_file", vm.import_trans_file[0]);

      try {
        vm.$store.commit("loadingStatus", true);
          await vm.$store.dispatch("getUserDetails").then(async(response) => {
            if(response.is_active){
              //console.log('test',response.permissionData.financial_graphs);
              if (
                response.permissionData &&
                response.permissionData.financial_graphs &&
                response.permissionData.financial_graphs.length > 0 &&
                (response.permissionData.financial_graphs.includes("Add") || response.permissionData.financial_graphs.includes("Update"))
              ){
                      const response = await financialGraph.import(formData);
                      vm.toastMessage(response.message, "success");
                      //vm.$emit("close");
                      vm.import_trans_file = null;
                      //vm.markStepComplete('step2'); 
                      vm.$emit("close");
                      vm.$emit("imported");
                }
              }
          });
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    async submitHandlerContract() {
      //console.log('contract');
      let vm = this;
      vm.$store.dispatch("getUserDetails")
      vm.submitted_contract = true;
      vm.v$.$touch();
      
      //const isValidate = await vm.v$.$validate();
      
      //if (!isValidate) return;
     // if(vm.getSpecificStep('step1') !== 1 && vm.getSpecificStep('step1') !== 2)
      if(vm.getSpecificStep('step1') !== 1)
      {
        //vm.toastMessage('Please Complete Step 1 and Step 2', "error");
        vm.toastMessage('Please Complete Step 1', "error");
        vm.submitted_contract = false;
        return;
      }
      let formData = new FormData();
      //console.log('formData',formData);
      formData.append("import_file", vm.import_contract_file[0]);

      try {
        vm.$store.commit("loadingStatus", true);
          await vm.$store.dispatch("getUserDetails").then(async(response) => {
            if(response.is_active){
              //console.log('test',response.permissionData.financial_graphs);
              if (
                response.permissionData &&
                response.permissionData.financial_graphs &&
                response.permissionData.financial_graphs.length > 0 &&
                (response.permissionData.financial_graphs.includes("Add") || response.permissionData.financial_graphs.includes("Update"))
              ){
                      const response = await financialGraph.import_contract(formData);
                      vm.toastMessage(response.message, "success");
                      vm.$emit("close");
                      vm.stepsCompleted();
                      vm.$emit("imported");
                }
              }
          });
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    uploadDocuments(type) {
      //console.log('not working!!!');
      let vm = this;
      vm.$store.dispatch("getUserDetails")
      let files= '';
      if(type == 'report'){
        files = vm.$refs.documents.files;
      }else if(type == 'transaction'){
        files = vm.$refs.documentstransaction.files;
      }else if(type == 'contract'){
        files = vm.$refs.documentscontract.files;
      }
      //if(vm.getSpecificStep('step1') !== 1 && vm.getSpecificStep('step2') !== 2 && type != 'report')
      /*if(vm.getSpecificStep('step1') !== 1 && type != 'report')
      {
        //vm.toastMessage('Please Complete Step 1 and Step 2', "error");
        vm.toastMessage('Please Complete Step 1', "error");
        vm.submitted_contract = false;
        return;
      }*/
      
      //console.log('files',files);
      const finalFile = Array.from(files);
      //console.log('Final',finalFile.length);
      for (let i = 0; i < finalFile.length; i++) {
        let ext = finalFile[i].name.split(".").pop();
        if (!vm.allowFileTypes.includes(ext)) {
          vm.toastMessage(
            `The documents must be a file of type: ${vm.allowFileTypes.join(
              ", "
            )}`,
            "error"
          );
          finalFile.splice(i, 1);
        }

        finalFile[i].path = URL.createObjectURL(finalFile[i]);

        if (finalFile[i].size > 102400 * 7) {
          vm.toastMessage("Document should be less or equal to 7MB.", "error");
          finalFile.splice(i, 1);
        }
      }

      if (finalFile.length > 0) {
        if(type == 'report'){
          vm.submitted = false;
          vm.import_file = finalFile;
        }else if(type == 'transaction'){
          vm.submitted_trans = false;
          vm.import_trans_file = finalFile;
        }else if(type == 'contract'){
          //console.log('file attached!!');
          vm.submitted_contract = false;
          vm.import_contract_file = finalFile;
        }
        
      }
    },
    removeDocument(index) {
      let vm = this;
      vm.import_file.splice(index, 1);
      vm.$refs.documents.value = '';
    },
    removeTransactionDocument(index) {
      let vm = this;
      vm.import_trans_file.splice(index, 1);
      vm.$refs.documentstransaction.value = '';
    },
    removeContractDocument(index) {
      let vm = this;
      vm.import_contract_file.splice(index, 1);
      vm.$refs.documentscontract.value = '';
    },
    setSteps(){
      localStorage.setItem('import_steps', JSON.stringify({'step1':0,'step2':0,'step3':0}));
    },
    getSpecificStep(ElementName){ 
      let ObjectData = JSON.parse(localStorage.getItem('import_steps'));
      return ObjectData[ElementName];
    },
    markStepComplete(step){
      let getObjectData = JSON.parse(localStorage.getItem('import_steps'));
      Object.keys(getObjectData).forEach(key => {
        console.log('key',key);
        getObjectData[step] = 1;
      });

      localStorage.setItem('import_steps', JSON.stringify(getObjectData));
    },
    stepsCompleted(){
      localStorage.removeItem('import_steps');
    }
  },
};
</script>
